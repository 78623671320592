body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-family: 'Now', sans-serif;
  letter-spacing: 0.2rem;
  font-size: 9px;
  line-height: 1.5;
  color: #000;
}

a {
  color: #e84646;
  text-decoration: none;
}


@font-face {
  font-family: Now;
  font-weight: normal;
  src: url("./fonts/now-regular.woff") format("woff");
}

@font-face {
  font-family: Now;
  font-weight: bold;
  src: url("./fonts/now-bold.woff") format("woff");
}

@font-face {
  font-family: Now;
  font-weight: 500;
  src: url("./fonts/now-medium.woff") format("woff");
}

@font-face {
  font-family: Now;
  font-weight: 200;
  src: url("./fonts/now-light.woff") format("woff");
}
